@charset "UTF-8";.pace .pace-progress,.pace .pace-progress-inner,.page-header.navbar,.page-header.navbar .top-menu .navbar-nav>li.dropdown-tasks .dropdown-menu .dropdown-menu-list>li .progress .progress-bar {
    box-shadow: none
}

@media print {
    body {
        background-color: #fff!important
    }

    .hidden-print,.page-bar,.page-footer,.page-quick-sidebar-wrapper,.page-sidebar-wrapper,.theme-panel {
        display: none
    }

    .no-page-break {
        page-break-after: avoid
    }

    .page-container {
        margin: 0!important;
        padding: 0!important
    }

    .page-content {
        min-height: 300px!important;
        padding: 0 20px 20px!important;
        margin: 0!important
    }
}

.page-header.navbar {
    width: 100%;
    margin: 0;
    border: 0;
    padding: 0;
    height: 46px;
    min-height: 46px;
    filter: none;
    background-image: none
}

.page-header.navbar.navbar-fixed-top,.page-header.navbar.navbar-static-top {
    z-index: 9995
}

.page-header.navbar .page-logo {
    float: left;
    display: block;
    width: 235px;
    height: 46px;
    padding-left: 20px;
    padding-right: 20px
}

.page-sidebar-closed.page-sidebar-closed-hide-logo .page-header.navbar .page-logo {
    padding: 0
}

.page-header.navbar .page-logo>.logo-image,.page-header.navbar .page-logo>a {
    display: inline-block;
    float: left
}

.page-header.navbar .page-logo .logo-default {
    margin: 16px 0 0
}

.page-header.navbar .page-logo .logo-mini {
    display: none;
    margin-left: 5px
}

.page-header.navbar .page-logo .text-logo {
    padding-left: 20px;
    padding-top: 12px
}

.page-header.navbar .search-form {
    display: inline-block;
    width: 46px;
    position: relative;
    float: left;
    transition: all .6s
}

.page-header.navbar .search-form .input-group .form-control {
    height: 46px;
    border: 0;
    background: 0 0!important;
    font-size: 13px;
    padding-left: 0;
    margin-left: 12px;
    text-indent: -150000px
}

.page-header.navbar .search-form .input-group .form-control:hover {
    cursor: pointer
}

.page-header.navbar .search-form .input-group .input-group-btn {
    height: 46px
}

.page-header.navbar .search-form .input-group .input-group-btn .btn.submit {
    margin-left: -24px;
    padding: 0;
    width: 46px;
    background: 0 0;
    margin-top: 4px;
    display: block
}

.page-header.navbar .search-form .input-group .input-group-btn .btn.submit>i {
    font-size: 15px
}

.page-header.navbar .search-form.open {
    transition: all .6s;
    width: 300px!important
}

.page-header.navbar .search-form.open .input-group .form-control {
    text-indent: 0
}

.page-header.navbar .search-form.open .input-group .form-control:hover {
    cursor: text
}

.page-header.navbar .search-form.open .input-group .input-group-btn .btn.submit {
    margin-left: 0
}

.page-header.navbar .menu-toggler {
    display: block;
    cursor: pointer;
    opacity: .7;
    filter: alpha(opacity=70);
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center center
}

.page-header.navbar .menu-toggler:hover {
    filter: alpha(opacity=100);
    opacity: 1
}

.page-header.navbar .menu-toggler.sidebar-toggler {
    float: right;
    margin: 11px 0 0
}

.page-sidebar-closed.page-sidebar-closed-hide-logo .page-header.navbar .menu-toggler.sidebar-toggler {
    margin-right: 10.5px
}

.page-header.navbar .menu-toggler.responsive-toggler {
    display: none;
    float: right;
    margin: 11px 6px 0
}

.page-header.navbar .top-menu {
    margin: 0;
    padding: 0;
    float: right
}

.page-header.navbar .top-menu .navbar-nav {
    padding: 0;
    margin-right: 20px;
    display: block
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown {
    margin: 0;
    padding: 0 4px;
    height: 46px;
    display: inline-block
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown:last-child {
    padding-right: 0
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown>.dropdown-toggle {
    margin: 0;
    padding: 17px 10px 9px
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown>.dropdown-toggle:last-child {
    padding-right: 0
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown>.dropdown-toggle>i {
    font-size: 17px
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown>.dropdown-toggle>i.glyphicon {
    font-size: 16px
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown>.dropdown-toggle>.badge {
    font-family: "Open Sans",sans-serif;
    position: absolute;
    top: 10px;
    right: 20px;
    font-weight: 300;
    padding: 3px 6px
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown>.dropdown-toggle:focus {
    background: 0 0
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-menu {
    margin-top: 3px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-menu:before {
    position: absolute;
    top: -7px;
    right: 9px;
    display: inline-block!important;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #eee;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0,0,0,.2);
    content: ''
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-menu:after {
    position: absolute;
    top: -6px;
    right: 10px;
    display: inline-block!important;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
    content: ''
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-menu>li>a {
    color: #555
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu {
    min-width: 160px;
    max-width: 275px;
    width: 275px;
    z-index: 9995
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu>li.external {
    display: block;
    overflow: hidden;
    padding: 15px;
    letter-spacing: .5px;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    -ms-border-radius: 4px 4px 0 0;
    -o-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu>li.external>h3 {
    margin: 0;
    padding: 0;
    float: left;
    font-size: 13px;
    display: inline-block
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu>li.external>a {
    display: inline-block;
    padding: 0;
    background: 0 0;
    clear: inherit;
    font-size: 13px;
    font-weight: 300;
    position: absolute;
    right: 10px;
    border: 0;
    margin-top: -1px
}

.page-bar:after,.page-container:after,.page-footer:after,.page-sidebar .page-sidebar-menu>li.sidebar-search-wrapper:after,.page-sidebar .page-sidebar-menu>li.sidebar-toggler-wrapper:after,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.sidebar-search-wrapper:after,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.sidebar-toggler-wrapper:after {
    clear: both
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu>li.external>a:hover {
    text-decoration: none
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu .dropdown-menu-list {
    padding-right: 0!important;
    padding-left: 0;
    list-style: none
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu .dropdown-menu-list>li>a {
    display: block;
    clear: both;
    font-weight: 300;
    line-height: 20px;
    white-space: normal;
    font-size: 13px;
    padding: 16px 15px 18px;
    text-shadow: none
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu .dropdown-menu-list>li>a:hover {
    opacity: 1;
    filter: alpha(opacity=100);
    text-decoration: none
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu .dropdown-menu-list>li:first-child a {
    border-top: none
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li a .details {
    overflow: hidden
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li a .details .label-icon {
    margin-right: 10px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li a .details .label-icon i {
    margin-right: 2px;
    margin-left: 1px
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li a .details .label-icon .badge {
    right: 15px
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li a .time {
    float: right;
    max-width: 75px;
    font-size: 11px;
    font-weight: 400;
    opacity: .7;
    filter: alpha(opacity=70);
    text-align: right;
    padding: 1px 5px
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox>.dropdown-menu .dropdown-menu-list>li .photo {
    float: left;
    margin: 0 6px 6px 0
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox>.dropdown-menu .dropdown-menu-list>li .photo img {
    height: 40px;
    width: 40px;
    -webkit-border-radius: 50%!important;
    -moz-border-radius: 50%!important;
    -ms-border-radius: 50%!important;
    -o-border-radius: 50%!important;
    border-radius: 50%!important
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox>.dropdown-menu .dropdown-menu-list>li .subject {
    display: block;
    margin-left: 46px
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox>.dropdown-menu .dropdown-menu-list>li .subject .from {
    font-size: 13px;
    font-weight: 600
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox>.dropdown-menu .dropdown-menu-list>li .subject .time {
    font-size: 12px;
    font-weight: 400;
    opacity: .5;
    filter: alpha(opacity=50);
    float: right
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox>.dropdown-menu .dropdown-menu-list>li .message {
    display: block!important;
    font-size: 12px;
    line-height: 1.3;
    margin-left: 46px
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-tasks .dropdown-menu .dropdown-menu-list>li .task {
    margin-bottom: 5px
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-tasks .dropdown-menu .dropdown-menu-list>li .task .desc {
    font-size: 13px;
    font-weight: 300
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-tasks .dropdown-menu .dropdown-menu-list>li .task .percent {
    float: right;
    font-weight: 600;
    display: inline-block
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-tasks .dropdown-menu .dropdown-menu-list>li .progress {
    display: block;
    height: 8px;
    margin: 8px 0 2px
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-toggle {
    padding: 14px 6px 12px 8px
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-toggle>.username {
    display: inline-block;
    font-size: 13px;
    font-weight: 300
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-toggle>img {
    float: left;
    margin-top: -5px;
    margin-right: 5px;
    height: 29px;
    display: inline-block
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-toggle>i {
    display: inline-block;
    margin: 0;
    font-size: 13px
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-menu {
    width: 175px
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-menu>li>a {
    font-size: 14px;
    font-weight: 300
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-menu>li>a i {
    width: 15px;
    display: inline-block;
    margin-right: 9px
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-menu>li>a .badge {
    margin-right: 10px
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-language {
    padding-left: 0;
    padding-right: 0;
    margin: 0
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-language>.dropdown-toggle {
    padding: 14px 3px 12px 7px
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-language>.dropdown-toggle>img {
    margin-bottom: 2px
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-language>.dropdown-toggle>i {
    font-size: 14px
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-language>.dropdown-menu>li>a {
    font-size: 13px
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-language>.dropdown-menu>li>a>img {
    margin-bottom: 2px;
    margin-right: 5px
}

.page-header.navbar .top-menu .navbar-nav li.dropdown-dark .dropdown-menu:before {
    border-left: none;
    border-right: none
}

.page-header.navbar .top-menu .navbar-nav li.dropdown-dark .dropdown-menu .dropdown-menu-list>li.external a {
    background: 0 0!important;
    border: none!important
}

@media (min-width: 768px) {
    .page-header.navbar .search-form.search-form-expanded {
        width:200px
    }

    .page-header.navbar .search-form.search-form-expanded .input-group .form-control {
        text-indent: 0
    }

    .page-header.navbar .search-form.search-form-expanded .input-group .form-control:hover {
        cursor: text
    }

    .page-header.navbar .search-form.search-form-expanded .input-group .input-group-btn .btn.submit {
        margin-left: 0
    }
}

.page-header.navbar .container {
    position: relative
}

.page-header.navbar .hor-menu .navbar-nav,.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown {
    position: static
}

.page-header.navbar .hor-menu {
    margin: 0;
    float: left
}

.page-header.navbar .hor-menu .navbar-nav.navbar-right .dropdown-menu {
    left: auto;
    right: 0
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu {
    left: auto;
    width: auto
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content {
    font-family: "Open Sans",sans-serif;
    padding: 15px;
    margin: 0
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content.mega-menu-responsive-content {
    padding: 10px 18px 10px 45px
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu {
    padding: 0;
    margin: 0
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu:last-child {
    border-right: 0
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li {
    margin: 0!important;
    list-style: none
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>h3 {
    margin-top: 5px;
    padding-left: 6px;
    font-size: 15px;
    font-weight: 400
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>a {
    display: block;
    white-space: normal;
    font-family: "Open Sans",sans-serif;
    padding: 7px;
    margin: 0;
    font-size: 14px;
    font-weight: 300
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>a:hover {
    text-decoration: none
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>a.iconify {
    padding: 7px 7px 7px 30px
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>a.iconify>i {
    position: absolute;
    top: auto!important;
    margin-left: -24px;
    font-size: 15px;
    margin-top: 3px!important
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>a .badge,.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>a .label {
    margin-left: 5px
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown.mega-menu-full .dropdown-menu {
    left: 20px;
    right: 20px
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown:hover>.dropdown-menu {
    display: block
}

.page-header.navbar .hor-menu .navbar-nav>li.menu-dropdown .dropdown-menu:after,.page-header.navbar .hor-menu .navbar-nav>li.menu-dropdown .dropdown-menu:before {
    display: none!important
}

.page-header.navbar .hor-menu .navbar-nav>li>a {
    font-size: 14px;
    font-weight: 400;
    padding: 13px
}

.page-header.navbar .hor-menu .navbar-nav>li>a:focus {
    background: 0 0!important
}

.page-header.navbar .hor-menu .navbar-nav>li.active .selected,.page-header.navbar .hor-menu .navbar-nav>li.current .selected {
    left: 50%;
    bottom: 0;
    position: absolute;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid transparent;
    display: inline-block;
    margin: 0 0 -6px -7px;
    width: 0;
    height: 0
}

.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu {
    margin-top: 0;
    border: none
}

.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li>a {
    font-family: "Open Sans",sans-serif;
    font-size: 14px;
    font-weight: 300;
    padding: 9px 10px;
    white-space: normal
}

.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li>a .badge,.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li>a .label {
    font-weight: 300
}

.page-header.navbar .hor-menu .navbar-nav>li.classic-menu-dropdown .dropdown-menu {
    min-width: 195px;
    max-width: 235px
}

.page-header.navbar .hor-menu .navbar-nav>li.classic-menu-dropdown:hover>.dropdown-menu {
    display: block
}

.page-header.navbar .hor-menu .navbar-nav>li .dropdown-submenu>.dropdown-menu {
    top: 0
}

.page-header.navbar .hor-menu .navbar-nav>li .dropdown-submenu>a:after {
    top: 9px;
    right: 10px
}

@media (min-width: 992px) and (max-width:1200px) {
    .page-boxed .page-header.navbar .top-menu .navbar-nav>li.dropdown-language .dropdown-toggle .langname,.page-boxed .page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-toggle .username.username-hide-on-mobile {
        display:none
    }
}

@media (min-width: 992px) {
    .page-sidebar-closed.page-sidebar-closed-hide-logo .page-header.navbar .page-logo {
        padding:0;
        width: 45px
    }

    .page-sidebar-closed.page-sidebar-closed-hide-logo .page-header.navbar .page-logo .logo-default {
        display: none
    }

    .page-boxed .page-header.navbar .page-logo {
        width: 236px
    }

    .page-boxed .page-header.navbar .top-menu .navbar-nav {
        margin-right: 0
    }

    .page-sidebar-closed.page-sidebar-closed-hide-logo.page-boxed .page-header.navbar .page-logo {
        width: 46px
    }

    .page-boxed.page-sidebar-fixed .page-header.navbar .page-logo {
        width: 235px
    }
}

@media (max-width: 991px) {
    .page-header.navbar {
        padding:0 20px;
        position: relative;
        clear: both
    }

    .page-header.navbar .page-logo {
        width: auto;
        padding: 0;
        margin-right: 10px;
        margin-left: 0!important;
        padding-left: 0!important
    }

    .page-header.navbar .page-logo img {
        margin-left: 4px!important
    }

    .page-header.navbar .menu-toggler.sidebar-toggler {
        display: none!important
    }

    .page-header.navbar .menu-toggler.responsive-toggler {
        display: inline-block
    }

    .page-header.navbar .top-menu .navbar-nav {
        display: inline-block;
        margin: 0 10px 0 0
    }

    .page-header.navbar .top-menu .navbar-nav>li {
        float: left
    }

    .page-header.navbar .top-menu .navbar-nav .nav li.dropdown i {
        display: inline-block;
        position: relative;
        top: 1px;
        right: 0
    }

    .page-header.navbar .top-menu .navbar-nav .open .dropdown-menu {
        position: absolute
    }

    .page-header-fixed.page-header-fixed-mobile .navbar-fixed-top {
        position: fixed
    }

    .page-boxed .page-header.navbar>.container {
        max-width: none!important;
        margin: 0!important;
        padding: 0!important
    }
}

@media (min-width: 768px) and (max-width:991px) {
    .page-boxed .page-header.navbar {
        margin:auto!important;
        padding: 0
    }

    .page-boxed .page-header.navbar>.container {
        margin: auto!important
    }
}

@media (max-width: 767px) {
    .page-header.navbar {
        padding:0 10px
    }

    .page-header.navbar .page-logo {
        width: auto
    }

    .page-header.navbar .search-form.open {
        z-index: 3;
        left: 10px;
        right: 10px;
        position: absolute;
        width: auto!important
    }

    .page-header.navbar .top-menu .navbar-nav>li.dropdown-extended>.dropdown-menu {
        max-width: 255px;
        width: 255px
    }

    .page-header.navbar .top-menu .navbar-nav>li.dropdown-notification .dropdown-menu {
        margin-right: -190px
    }

    .page-header.navbar .top-menu .navbar-nav>li.dropdown-notification .dropdown-menu:after,.page-header.navbar .top-menu .navbar-nav>li.dropdown-notification .dropdown-menu:before {
        margin-right: 190px
    }

    .page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox .dropdown-menu {
        margin-right: -150px
    }

    .page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox .dropdown-menu:after,.page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox .dropdown-menu:before {
        margin-right: 150px
    }

    .page-header.navbar .top-menu .navbar-nav>li.dropdown-tasks .dropdown-menu {
        margin-right: -110px
    }

    .page-header.navbar .top-menu .navbar-nav>li.dropdown-tasks .dropdown-menu:after,.page-header.navbar .top-menu .navbar-nav>li.dropdown-tasks .dropdown-menu:before {
        margin-right: 110px
    }
}

@media (max-width: 580px) {
    .page-header.navbar .top-menu .navbar-nav>li.dropdown-language .dropdown-toggle .langname,.page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-toggle .username.username-hide-on-mobile {
        display:none
    }
}

@media (max-width: 480px) {
    .page-header-fixed.page-header-fixed-mobile .page-header.navbar {
        height:92px
    }

    .page-header.navbar .top-menu {
        display: block;
        clear: both;
        float: none
    }

    .page-header.navbar .top-menu .navbar-nav {
        margin-right: 0
    }

    .page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-toggle {
        padding: 17px 6px 9px
    }

    .page-header.navbar .top-menu .navbar-nav>li.dropdown-language .dropdown-toggle {
        padding: 14px 4px 12px 2px
    }

    .page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-toggle {
        padding: 14px 0 12px 2px
    }
}

.pace .pace-progress {
    z-index: 10005;
    top: 46px;
    height: 2px
}

.pace .pace-inactive {
    display: none
}

.pace .pace-activity {
    top: 50px;
    z-index: 10005;
    right: 20px;
    border-radius: 10px!important
}

.page-container {
    margin: 0;
    padding: 0;
    position: relative
}

.page-container:after,.page-container:before {
    content: " ";
    display: table
}

.page-header-fixed .page-container {
    margin-top: 46px
}

.page-footer-fixed.page-footer-fixed-mobile .page-container {
    margin-bottom: 20px!important
}

@media (min-width: 992px) {
    .page-footer-fixed .page-container {
        margin-bottom:20px!important
    }
}

@media (max-width: 991px) {
    .page-container {
        margin:0!important;
        padding: 0!important
    }

    .page-header-fixed.page-header-fixed-mobile .page-container {
        margin-top: 46px!important
    }
}

@media (max-width: 480px) {
    .page-header-fixed .pace .pace-progress {
        top:92px
    }

    .page-header-fixed .pace .pace-activity {
        top: 96px
    }

    .page-header-fixed.page-header-fixed-mobile .page-container {
        margin-top: 92px!important
    }
}

.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover.navbar-collapse,.page-sidebar.navbar-collapse {
    padding: 0;
    box-shadow: none
}

.page-sidebar .page-sidebar-menu,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu {
    list-style: none;
    margin: 0;
    padding: 0
}

.page-sidebar .page-sidebar-menu>li,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li {
    display: block;
    margin: 0;
    padding: 0;
    border: 0
}

.page-sidebar .page-sidebar-menu>li.sidebar-search-wrapper,.page-sidebar .page-sidebar-menu>li.sidebar-toggler-wrapper,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.sidebar-search-wrapper,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.sidebar-toggler-wrapper {
    border: 0!important
}

.page-sidebar .page-sidebar-menu>li.sidebar-search-wrapper:after,.page-sidebar .page-sidebar-menu>li.sidebar-search-wrapper:before,.page-sidebar .page-sidebar-menu>li.sidebar-toggler-wrapper:after,.page-sidebar .page-sidebar-menu>li.sidebar-toggler-wrapper:before,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.sidebar-search-wrapper:after,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.sidebar-search-wrapper:before,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.sidebar-toggler-wrapper:after,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.sidebar-toggler-wrapper:before {
    content: " ";
    display: table
}

.page-sidebar .page-sidebar-menu>li.start>a,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.start>a {
    border-top-color: transparent!important
}

.page-sidebar .page-sidebar-menu>li.last>a,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.last>a {
    border-bottom-color: transparent!important
}

.page-sidebar .page-sidebar-menu>li>a,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li>a {
    display: block;
    position: relative;
    margin: 0;
    border: 0;
    padding: 10px 15px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 300
}

.page-sidebar .page-sidebar-menu>li>a>i,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li>a>i {
    font-size: 16px;
    margin-right: 5px;
    text-shadow: none
}

.page-sidebar .page-sidebar-menu>li>a>[class^=icon-],.page-sidebar .page-sidebar-menu>li>a>i.glyphicon,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li>a>[class^=icon-],.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li>a>i.glyphicon {
    margin-left: 1px;
    margin-right: 4px
}

.page-sidebar-fixed .page-sidebar .page-sidebar-menu>li>a,.page-sidebar-fixed .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li>a {
    transition: all .2s ease
}

.page-sidebar-reversed.page-sidebar-fixed .page-sidebar .page-sidebar-menu>li>a,.page-sidebar-reversed.page-sidebar-fixed .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li>a {
    transition: none
}

.page-sidebar .page-sidebar-menu>li.heading,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.heading {
    padding: 15px
}

.page-sidebar .page-sidebar-menu>li.heading>h3,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.heading>h3 {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 300
}

.page-sidebar .page-sidebar-menu>li.heading+li>a,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.heading+li>a {
    border-top: 0
}

.page-sidebar .page-sidebar-menu>li.open>a,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.open>a {
    font-size: 14px
}

.page-sidebar .page-sidebar-menu>li.active>a,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.active>a {
    border: none;
    text-shadow: none;
    font-size: 14px
}

.page-sidebar .page-sidebar-menu>li.active>a>.selected,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.active>a>.selected {
    display: block;
    float: right;
    position: absolute;
    right: 0;
    top: 8px;
    background: 0 0;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid #fff
}

.page-sidebar-reversed .page-sidebar .page-sidebar-menu>li.active>a>.selected,.page-sidebar-reversed .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.active>a>.selected {
    right: auto;
    left: 0;
    border-right: 0;
    border-left: 8px solid #fff
}

.page-container-bg-solid .page-sidebar .page-sidebar-menu>li.active>a>.selected,.page-container-bg-solid .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.active>a>.selected {
    border-color: transparent #eef1f5 transparent transparent
}

.page-container-bg-solid.page-sidebar-reversed .page-sidebar .page-sidebar-menu>li.active>a>.selected,.page-container-bg-solid.page-sidebar-reversed .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li.active>a>.selected {
    border-color: transparent transparent transparent #eef1f5
}

.page-sidebar .page-sidebar-menu li>a,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li>a {
    position: relative
}

.page-sidebar .page-sidebar-menu li>a>.arrow:before,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li>a>.arrow:before {
    float: right;
    width: 20px;
    text-align: center;
    display: inline;
    font-size: 16px;
    font-family: FontAwesome;
    height: auto;
    content: "\f104";
    font-weight: 300;
    text-shadow: none;
    position: absolute;
    top: 4px;
    right: 14px
}

.page-sidebar .page-sidebar-menu li>a>.arrow.open:before,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li>a>.arrow.open:before {
    content: "\f107"
}

.page-sidebar .page-sidebar-menu li>a>.badge,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li>a>.badge {
    float: right;
    margin-top: 1px;
    margin-right: 0;
    position: absolute;
    right: 14px;
    top: 6px
}

.page-sidebar .page-sidebar-menu>li>a>.arrow:before,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li>a>.arrow:before {
    top: 8px
}

.page-sidebar .page-sidebar-menu .sub-menu,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu {
    list-style: none;
    display: none;
    padding: 0;
    margin: 8px 0
}

.page-sidebar .page-sidebar-menu .sub-menu li,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li {
    background: 0 0;
    margin: 0;
    padding: 0;
    margin-top: 1px!important
}

.page-sidebar .page-sidebar-menu .sub-menu li>a,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li>a {
    display: block;
    margin: 0;
    padding: 6px 15px 6px 43px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 300;
    background: 0 0
}

.page-sidebar .page-sidebar-menu .sub-menu li>a>i,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li>a>i {
    font-size: 14px
}

.page-sidebar .page-sidebar-menu .sub-menu li>.sub-menu,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li>.sub-menu {
    margin: 0
}

.page-sidebar .page-sidebar-menu .sub-menu li>.sub-menu>li>a,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li>.sub-menu>li>a {
    padding-left: 60px
}

.page-sidebar .page-sidebar-menu .sub-menu li>.sub-menu>li>.sub-menu,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li>.sub-menu>li>.sub-menu {
    margin: 0
}

.page-sidebar .page-sidebar-menu .sub-menu li>.sub-menu>li>.sub-menu>li>a,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu li>.sub-menu>li>.sub-menu>li>a {
    padding-left: 80px
}

.page-sidebar .page-sidebar-menu .sub-menu.always-open,.page-sidebar .page-sidebar-menu li.active>.sub-menu,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu.always-open,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li.active>.sub-menu {
    display: block
}

.page-sidebar .page-sidebar-menu.page-sidebar-menu-light>li>a,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light>li>a {
    border: 0;
    margin: 0;
    padding-left: 11px;
    border-left: 4px solid transparent
}

.page-sidebar .page-sidebar-menu.page-sidebar-menu-light>li .sub-menu,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light>li .sub-menu {
    margin: 0;
    padding: 1px 0
}

.page-sidebar .page-sidebar-menu.page-sidebar-menu-light>li .sub-menu li>a,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light>li .sub-menu li>a {
    padding-top: 8px;
    padding-bottom: 8px
}

.page-sidebar .page-sidebar-menu.page-sidebar-menu-light>li .sub-menu li:first-child,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light>li .sub-menu li:first-child {
    margin-top: 0!important
}

.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-light>li>a,.page-sidebar-reversed .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light>li>a {
    padding-left: 15px;
    padding-right: 11px;
    border-left: 0;
    border-right: 4px solid transparent
}

.page-sidebar .sidebar-toggler,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-toggler {
    display: block;
    cursor: pointer;
    opacity: .7;
    filter: alpha(opacity=70);
    width: 30px;
    height: 27px;
    margin-top: 15px;
    margin-right: 19px;
    float: right;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px
}

.page-sidebar .sidebar-toggler:hover,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-toggler:hover {
    filter: alpha(opacity=100);
    opacity: 1
}

.page-sidebar .sidebar-search,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search {
    padding: 0;
    margin: 22px 18px
}

.page-sidebar .sidebar-search .remove,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .remove {
    display: none
}

.page-sidebar .sidebar-search .remove>i,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .remove>i {
    font-size: 16px
}

.page-sidebar .sidebar-search .input-group,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px
}

.page-sidebar .sidebar-search .input-group .form-control,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group .form-control {
    border: 0;
    font-size: 14px;
    padding: 0;
    height: auto;
    line-height: auto;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px
}

.page-sidebar .sidebar-search .input-group .input-group-btn .btn,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group .input-group-btn .btn {
    padding: 2px 0 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 100% 3px
}

.page-sidebar .sidebar-search .input-group .input-group-btn .btn>i,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group .input-group-btn .btn>i {
    font-size: 15px
}

.page-sidebar .sidebar-search.sidebar-search-bordered,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.sidebar-search-bordered {
    margin: 25px 18px
}

.page-sidebar .sidebar-search.sidebar-search-bordered .input-group .form-control,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.sidebar-search-bordered .input-group .form-control {
    font-size: 13px;
    padding: 6px 8px
}

.page-sidebar .sidebar-search.sidebar-search-bordered .input-group .input-group-btn .btn,.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.sidebar-search-bordered .input-group .input-group-btn .btn {
    margin-right: 6px
}

@media (min-width: 992px) {
    .page-sidebar {
        width:235px;
        float: left;
        position: relative;
        margin-right: -100%
    }

    .page-full-width .page-sidebar {
        display: none!important
    }

    .page-sidebar.collapse {
        display: block;
        max-height: none!important
    }

    .page-sidebar-reversed .page-sidebar {
        float: right;
        margin-right: 0;
        margin-left: -100%
    }

    .page-sidebar-reversed.page-sidebar-fixed .page-sidebar {
        margin-left: -235px
    }

    .page-sidebar-reversed.page-sidebar-fixed .page-sidebar-wrapper {
        position: relative;
        float: right
    }

    .page-sidebar-fixed .page-sidebar {
        position: fixed!important;
        margin-left: 0;
        top: 46px
    }

    .page-sidebar-fixed .page-sidebar-menu>li.last {
        margin-bottom: 15px!important
    }

    .page-sidebar-fixed .page-sidebar-menu .sub-menu {
        height: auto!important
    }

    .page-sidebar-closed .page-sidebar,.page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed {
        width: 45px!important
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li.open>.sub-menu,.page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li>.sub-menu {
        display: none!important
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:hover {
        width: 256px!important;
        position: relative!important;
        z-index: 10000;
        display: block!important
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:hover>a {
        -webkit-border-radius: 0 4px 0 0;
        -moz-border-radius: 0 4px 0 0;
        -ms-border-radius: 0 4px 0 0;
        -o-border-radius: 0 4px 0 0;
        border-radius: 0 4px 0 0
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:hover>a>i {
        margin-right: 10px
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:hover>a>.title {
        display: inline!important;
        padding-left: 15px
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:hover>a>.badge {
        display: block!important
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:hover>a>.selected {
        display: none
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:hover.heading {
        width: 45px!important;
        box-shadow: none
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:hover>.sub-menu {
        width: 210px;
        position: absolute;
        z-index: 2000;
        left: 46px;
        margin-top: 0;
        top: 100%;
        display: block!important;
        -webkit-border-radius: 0 0 4px 4px;
        -moz-border-radius: 0 0 4px 4px;
        -ms-border-radius: 0 0 4px 4px;
        -o-border-radius: 0 0 4px 4px;
        border-radius: 0 0 4px 4px
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:hover>.sub-menu>li>a {
        padding-left: 15px!important
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:hover>.sub-menu>li>.sub-menu>li>a {
        padding-left: 30px!important
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:hover>.sub-menu>li>.sub-menu>li>.sub-menu>li>a {
        padding-left: 45px!important
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li.heading>h3 {
        display: none
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li.sidebar-toggler-wrapper .sidebar-toggler {
        margin-right: 8px
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li.sidebar-search-wrapper:hover,.page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li.sidebar-toggler-wrapper:hover {
        width: 45px!important
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li>a {
        padding-left: 11px
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li>a .selected {
        right: -3px!important
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li>a>.arrow,.page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li>a>.badge,.page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li>a>.title {
        display: none!important
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-toggler {
        margin-left: 3px;
        margin-right: 3px
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search .input-group {
        border-color: transparent;
        margin-left: -4px
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search .input-group .form-control {
        display: none
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search .input-group .input-group-btn .btn {
        display: block
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.sidebar-search-bordered .input-group {
        padding: 5px 0 3px
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open {
        height: 40px;
        margin-top: 15px;
        margin-bottom: 14px
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open .input-group {
        width: 210px;
        position: relative;
        z-index: 1;
        margin-left: 24px;
        padding: 0
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open .input-group .form-control {
        background: 0 0;
        border: 0;
        display: block;
        padding: 8px
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open .input-group .input-group-btn .btn {
        display: block;
        margin-right: 8px;
        margin-top: 1px
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open .remove {
        background-repeat: no-repeat;
        width: 11px;
        height: 11px;
        margin: 10px -5px 8px -7px;
        display: block;
        float: left
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open.sidebar-search-bordered {
        height: 38px;
        margin-top: 23px;
        margin-bottom: 23px
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open.sidebar-search-bordered .input-group {
        padding: 0
    }

    .page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-light.page-sidebar-menu-closed>li>a {
        padding-right: 11px;
        padding-left: 7px
    }

    .page-sidebar-closed.page-sidebar-reversed .page-sidebar {
        margin-left: -45px;
        width: 45px
    }

    .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li>.sub-menu {
        left: auto;
        right: 46px
    }

    .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:hover {
        margin-left: -211px
    }

    .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:hover>a {
        -webkit-border-radius: 4px 0 0;
        -moz-border-radius: 4px 0 0;
        -ms-border-radius: 4px 0 0 0;
        -o-border-radius: 4px 0 0;
        border-radius: 4px 0 0
    }

    .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:hover>a>.title {
        padding-left: 0;
        padding-right: 15px
    }

    .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:hover>a>i {
        margin-right: 0;
        margin-left: 2px
    }

    .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li.sidebar-search-wrapper:hover,.page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li.sidebar-toggler-wrapper:hover {
        margin-left: 0
    }

    .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open .input-group {
        margin-left: -227px
    }

    .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open .input-group .input-group-btn .btn {
        margin-right: 10px!important
    }

    .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed .sidebar-search.open .remove {
        margin: 9px 4px 12px -16px!important;
        float: right!important
    }

    .page-sidebar-closed.page-sidebar-reversed .page-sidebar .page-sidebar-menu.page-sidebar-menu-light.page-sidebar-menu-closed>li>a {
        padding-right: 7px;
        padding-left: 11px
    }

    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover {
        width: 235px!important;
        display: block;
        z-index: 10000
    }

    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .selected,.page-sidebar-closed.page-sidebar-hide .page-sidebar {
        display: none!important
    }

    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu {
        width: 235px!important
    }

    .page-sidebar-closed.page-sidebar-fixed.page-sidebar-reversed .page-sidebar:hover {
        width: 235px!important;
        z-index: 10000;
        margin-left: -235px!important
    }

    .page-sidebar-closed.page-sidebar-fixed.page-sidebar-reversed .page-sidebar:hover .page-sidebar-menu {
        width: 235px!important
    }

    .page-sidebar-menu.page-sidebar-menu-hover-submenu li .sub-menu {
        display: none;
        width: 210px;
        z-index: 2000;
        position: absolute;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        border-radius: 4px
    }

    .page-sidebar-menu.page-sidebar-menu-hover-submenu li .sub-menu>li>a {
        margin: 3px
    }

    .page-sidebar-menu.page-sidebar-menu-hover-submenu li.active .sub-menu,.page-sidebar-menu.page-sidebar-menu-hover-submenu li.open .sub-menu {
        display: none!important
    }

    .page-sidebar-menu.page-sidebar-menu-hover-submenu li a>.arrow {
        display: none
    }

    .page-sidebar-menu.page-sidebar-menu-hover-submenu li:hover>a>.arrow {
        display: block;
        float: right;
        position: absolute;
        right: 0;
        margin-top: -20px;
        background: 0 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-top: 12px double transparent;
        border-bottom: 12px double transparent;
        border-left: 0
    }

    .page-sidebar-menu.page-sidebar-menu-hover-submenu li:hover>a>.arrow:after,.page-sidebar-menu.page-sidebar-menu-hover-submenu li:hover>a>.arrow:before {
        display: none
    }

    .page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-hover-submenu li:hover>a>.arrow {
        right: auto;
        left: 0;
        border-right: 0
    }

    .page-sidebar-menu.page-sidebar-menu-hover-submenu li:hover>.sub-menu {
        display: inline-block!important
    }

    .page-sidebar-menu.page-sidebar-menu-hover-submenu>li:hover>a>.arrow {
        z-index: 1;
        right: 0;
        margin-top: -23px
    }

    .page-sidebar-menu.page-sidebar-menu-hover-submenu>li:hover>a>.selected {
        display: none
    }

    .page-sidebar-menu.page-sidebar-menu-hover-submenu>li:hover>.sub-menu {
        margin-left: 235px;
        margin-top: -40px
    }

    .page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-hover-submenu>li:hover>.sub-menu {
        margin-left: -210px!important
    }

    .page-sidebar-closed .page-sidebar-menu.page-sidebar-menu-hover-submenu>li:hover>.sub-menu {
        margin-left: 0
    }

    .page-sidebar-menu.page-sidebar-menu-hover-submenu>li:hover>.sub-menu>li>a {
        padding-left: 15px
    }

    .page-sidebar-menu.page-sidebar-menu-hover-submenu>li:hover>.sub-menu>li .sub-menu {
        margin-left: 210px;
        margin-top: -38px!important
    }

    .page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-hover-submenu>li:hover>.sub-menu>li .sub-menu {
        margin-left: -210px!important
    }

    .page-sidebar-menu.page-sidebar-menu-hover-submenu>li:hover>.sub-menu>li .sub-menu>li>a {
        padding-left: 10px;
        padding-right: 10px
    }

    .page-sidebar-menu.page-sidebar-menu-light.page-sidebar-menu-hover-submenu li:hover>.sub-menu,.page-sidebar-menu.page-sidebar-menu-light.page-sidebar-menu-hover-submenu li:hover>.sub-menu>li>.sub-menu {
        margin-top: -41px
    }
}

@media (max-width: 991px) {
    .page-sidebar,.page-sidebar.navbar-collapse.in {
        border-top:0!important;
        margin: 20px
    }

    .page-sidebar .sidebar-toggler {
        display: none
    }

    .page-sidebar .selected,.page-sidebar.navbar-collapse.collapse {
        display: none!important
    }

    .page-sidebar.navbar-collapse {
        max-height: none
    }

    .page-sidebar.navbar-collapse.in {
        position: relative;
        overflow: hidden!important;
        overflow-y: auto!important;
        display: block!important
    }

    .page-sidebar.navbar-collapse.navbar-no-scroll {
        max-height: none!important
    }

    .page-sidebar .mega-menu-responsive-content {
        padding: 10px 18px 10px 45px
    }

    .page-full-width .page-sidebar-menu {
        display: block
    }
}

@media (min-width: 768px) and (max-width:991px) {
    .page-sidebar .btn-navbar.collapsed .arrow {
        display:none
    }

    .page-sidebar .btn-navbar .arrow {
        position: absolute;
        right: 25px;
        width: 0;
        height: 0;
        top: 50px;
        border-bottom: 15px solid #5f646b;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent
    }
}

@media (max-width: 480px) {
    .page-sidebar,.page-sidebar.in {
        margin:0 10px 10px!important
    }

    .page-header-fixed.page-header-fixed-mobile .page-sidebar,.page-header-fixed.page-header-fixed-mobile .page-sidebar.in {
        margin-top: 10px!important
    }
}

.page-title {
    padding: 0;
    font-size: 28px;
    letter-spacing: -1px;
    display: block;
    color: #666;
    margin: 0 0 15px;
    font-weight: 300
}

.page-title small {
    font-size: 14px;
    letter-spacing: 0;
    font-weight: 300;
    color: #888
}

.page-container-bg-solid .page-title,.page-content-white .page-title {
    color: #666;
    margin-bottom: 20px;
    margin-top: 20px
}

.page-container-bg-solid .page-title small,.page-content-white .page-title small {
    color: #666
}

.page-content-white .page-title {
    margin: 25px 0;
    font-size: 24px
}

.page-bar {
    padding: 0;
    background-color: #f1f4f7;
    margin-bottom: 25px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px
}

.page-bar:after,.page-bar:before {
    content: " ";
    display: table
}

.page-bar .page-breadcrumb {
    display: inline-block;
    float: left;
    padding: 8px;
    margin: 0;
    list-style: none
}

.page-bar .page-breadcrumb>li {
    display: inline-block
}

.ie8 .page-bar .page-breadcrumb>li {
    margin-right: 1px
}

.page-bar .page-breadcrumb>li>a,.page-bar .page-breadcrumb>li>span {
    color: #888;
    font-size: 14px;
    text-shadow: none
}

.page-bar .page-breadcrumb>li>i {
    color: #aaa;
    font-size: 14px;
    text-shadow: none
}

.page-bar .page-breadcrumb>li>i[class*=icon-],.page-bar .page-breadcrumb>li>i[class^=icon-] {
    color: gray
}

.page-bar .page-toolbar {
    display: inline-block;
    float: right;
    padding: 0
}

.page-bar .page-toolbar .btn-fit-height {
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    -ms-border-radius: 0 4px 4px 0;
    -o-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
    padding-top: 8px;
    padding-bottom: 8px
}

.page-md .page-bar .page-toolbar .btn-fit-height {
    padding-top: 9px;
    padding-bottom: 9px;
    box-shadow: none!important
}

.page-container-bg-solid .page-bar,.page-content-white .page-bar {
    background-color: #fff;
    position: relative;
    padding: 0 20px;
    margin: -25px -20px 0
}

.page-container-bg-solid .page-bar .page-breadcrumb,.page-content-white .page-bar .page-breadcrumb {
    padding: 11px 0
}

.page-container-bg-solid .page-bar .page-breadcrumb>li>a,.page-container-bg-solid .page-bar .page-breadcrumb>li>span,.page-content-white .page-bar .page-breadcrumb>li>a,.page-content-white .page-bar .page-breadcrumb>li>span {
    color: #888
}

.page-container-bg-solid .page-bar .page-breadcrumb>li>i,.page-content-white .page-bar .page-breadcrumb>li>i {
    color: #aaa
}

.page-container-bg-solid .page-bar .page-breadcrumb>li>i.fa-circle,.page-content-white .page-bar .page-breadcrumb>li>i.fa-circle {
    font-size: 5px;
    margin: 0 5px;
    position: relative;
    top: -3px;
    opacity: .4;
    filter: alpha(opacity=40)
}

.page-container-bg-solid .page-bar .page-breadcrumb>li>i[class*=icon-],.page-container-bg-solid .page-bar .page-breadcrumb>li>i[class^=icon-],.page-content-white .page-bar .page-breadcrumb>li>i[class*=icon-],.page-content-white .page-bar .page-breadcrumb>li>i[class^=icon-] {
    color: #8c8c8c
}

.page-container-bg-solid .page-bar .page-toolbar,.page-content-white .page-bar .page-toolbar {
    padding: 6px 0
}

.page-container-bg-solid .page-bar .page-toolbar .btn,.page-content-white .page-bar .page-toolbar .btn {
    margin-top: -2px
}

.page-container-bg-solid .page-bar .page-toolbar .btn.btn-sm,.page-content-white .page-bar .page-toolbar .btn.btn-sm {
    margin-top: 0
}

.page-content {
    margin-top: 0;
    padding: 0;
    background-color: #fff
}

.page-container-bg-solid .page-content {
    background: #eef1f5
}

.page-content-white .page-content .page-bar {
    border-bottom: 1px solid #e7ecf1
}

.page-container-bg-solid.page-md .page-content .page-bar,.page-content-white.page-md .page-content .page-bar {
    border-radius: 0!important
}

.page-full-width .page-content {
    margin-left: 0!important
}

@media (min-width: 992px) {
    .page-content-wrapper {
        float:left;
        width: 100%
    }

    .page-content-wrapper .page-content {
        margin-left: 235px;
        margin-top: 0;
        min-height: 600px;
        padding: 25px 20px 10px
    }

    .page-content-wrapper .page-content.no-min-height {
        min-height: auto
    }

    .page-sidebar-fixed.page-sidebar-hover-on .page-content-wrapper .page-content {
        margin-left: 45px
    }

    .page-sidebar-reversed .page-content-wrapper .page-content {
        margin-left: 0!important;
        margin-right: 235px!important
    }

    .page-sidebar-reversed.page-sidebar-fixed.page-sidebar-hover-on .page-content-wrapper .page-content {
        margin-left: 0;
        margin-right: 45px
    }

    .page-sidebar-reversed.page-sidebar-closed .page-content-wrapper .page-content {
        margin-left: 0!important;
        margin-right: 45px!important
    }

    .page-sidebar-closed .page-content-wrapper .page-content {
        margin-left: 45px!important
    }

    .page-full-width .page-content-wrapper .page-content,.page-sidebar-closed.page-sidebar-hide .page-content-wrapper .page-content {
        margin-left: 0!important
    }

    .page-sidebar-closed.page-sidebar-reversed.page-sidebar-hide .page-content-wrapper .page-content {
        margin-right: 0!important
    }
}

@media (max-width: 991px) {
    .page-container-bg-solid .page-bar,.page-content-white .page-bar {
        margin-top:-20px
    }

    .page-boxed>.container {
        max-width: none!important;
        margin: 0!important;
        padding: 0!important
    }

    .page-content-wrapper .page-content {
        margin: 0!important;
        padding: 20px!important;
        min-height: 280px
    }
}

@media (min-width: 768px) and (max-width:991px) {
    .page-boxed>.container {
        margin:auto!important
    }
}

@media (max-width: 767px) {
    .page-content-wrapper .page-content {
        padding:20px 10px 10px!important;
        overflow: hidden
    }

    .page-content-wrapper .page-content .page-title {
        margin-bottom: 20px;
        font-size: 18px
    }

    .page-content-wrapper .page-content .page-title small {
        font-size: 13px;
        padding-top: 3px
    }
}

@media (max-width: 480px) {
    .page-content-wrapper .page-content .page-title small {
        display:block;
        clear: both
    }
}

.page-footer {
    padding: 8px 20px 5px;
    font-size: 13px;
    height: 33px
}

.page-footer:after,.page-footer:before {
    content: " ";
    display: table
}

.page-footer .page-footer-inner {
    float: left;
    display: inline-block
}

.page-footer-fixed.page-footer-fixed-mobile .page-footer {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 10000;
    bottom: 0
}

.page-footer-fixed.page-footer-fixed-mobile.page-sidebar-fixed .page-footer {
    margin-left: 0!important
}

@media (min-width: 992px) {
    .page-footer {
        clear:left
    }

    .page-footer-fixed .page-footer {
        position: fixed;
        left: 0;
        right: 0;
        z-index: 10000;
        bottom: 0
    }

    .page-sidebar-fixed.page-sidebar-closed .page-footer {
        margin-left: 45px
    }

    .page-sidebar-fixed.page-footer-fixed .page-footer {
        margin-left: 0!important
    }

    .page-sidebar-fixed .page-footer {
        margin-left: 235px;
        padding: 8px 20px 5px
    }

    .page-boxed .page-footer {
        padding: 8px 0 5px
    }

    .page-boxed.page-sidebar-fixed .page-footer {
        padding-right: 20px;
        padding-left: 20px
    }

    .page-sidebar-reversed.page-sidebar-fixed .page-footer {
        margin-left: 0;
        margin-right: 235px;
        padding: 8px 20px 5px
    }

    .page-sidebar-reversed.page-sidebar-fixed.page-footer-fixed .page-footer {
        margin-left: 0;
        margin-right: 0
    }

    .page-sidebar-reversed.page-sidebar-fixed.page-sidebar-closed .page-footer {
        margin-right: 45px
    }
}

@media (max-width: 991px) {
    .page-boxed .page-footer {
        padding-left:0;
        padding-right: 0
    }
}

@media (max-width: 767px) {
    .page-boxed .page-footer,.page-footer {
        padding-left:10px;
        padding-right: 10px
    }

    .page-footer-fixed .page-footer .container {
        padding-left: 0;
        padding-right: 0
    }
}

.scroll-to-top {
    padding: 1px;
    text-align: center;
    position: fixed;
    bottom: 10px;
    z-index: 10001;
    display: none;
    right: 10px
}

.theme-panel>.toggler,.theme-panel>.toggler-close {
    padding: 20px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    top: 4px;
    cursor: pointer
}

.scroll-to-top>i {
    display: inline-block;
    color: #687991;
    font-size: 30px;
    opacity: .6;
    filter: alpha(opacity=60)
}

.scroll-to-top:hover {
    cursor: pointer
}

.scroll-to-top:hover>i {
    opacity: 1;
    filter: alpha(opacity=100)
}

@media (min-width: 992px) {
    .scroll-to-top {
        right:20px
    }
}

@media (max-width: 991px) {
    .scroll-to-top {
        bottom:10px;
        right: 10px
    }

    .scroll-to-top>i {
        font-size: 28px
    }
}

.theme-panel {
    width: 420px;
    margin-top: -13px;
    margin-right: 0;
    z-index: 100;
    float: right;
    position: relative
}

.theme-panel>.toggler {
    right: 0;
    position: absolute;
    /* background: url(../img/icon-color.png) center no-repeat #536881; */
    border-radius: 4px
}

.theme-panel>.toggler:hover {
    background-color: #3f4f62!important
}

.theme-panel>.toggler-close {
    display: none;
    right: 0;
    z-index: 101;
    position: absolute;
    /* background: url(../img/icon-color-close.png) center no-repeat #2b3643!important; */
    border-radius: 4px
}

.theme-panel>.toggler-close:hover {
    background-color: #212933!important
}

.theme-panel>.theme-options {
    top: 4px;
    right: 0;
    display: none;
    position: absolute;
    z-index: 100;
    background: #2b3643;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px
}

.theme-panel>.theme-options>.theme-option {
    color: #c6cfda;
    padding: 15px;
    border-top: 1px solid #354353;
    margin-top: 0;
    margin-bottom: 0
}

.theme-panel>.theme-options>.theme-option>span {
    text-transform: uppercase;
    display: inline-block;
    width: 145px;
    font-size: 13px;
    font-weight: 300
}

.theme-panel>.theme-options>.theme-option>select.form-control {
    display: inline;
    width: 135px;
    padding: 2px;
    text-transform: lowercase
}

.theme-panel>.theme-options>.theme-option.theme-colors {
    border-top: 0
}

.theme-panel>.theme-options>.theme-option.theme-colors>span {
    display: block;
    width: auto
}

.theme-panel>.theme-options>.theme-option.theme-colors>ul {
    list-style: none;
    padding: 0;
    display: block;
    margin-bottom: 10px!important;
    margin-top: 15px
}

.theme-panel>.theme-options>.theme-option.theme-colors>ul>li {
    width: 40px;
    height: 40px;
    margin: 0 4px;
    cursor: pointer;
    list-style: none;
    float: left;
    border: 1px solid #707070
}

.theme-panel>.theme-options>.theme-option.theme-colors>ul>li:first-child {
    margin-left: 0
}

.theme-panel>.theme-options>.theme-option.theme-colors>ul>li.current,.theme-panel>.theme-options>.theme-option.theme-colors>ul>li:hover {
    border: 2px solid #d64635
}

.theme-panel>.theme-options>.theme-option.theme-colors>ul>li.color-default {
    background: #333438
}

.theme-panel>.theme-options>.theme-option.theme-colors>ul>li.color-darkblue {
    background: #2b3643
}

.theme-panel>.theme-options>.theme-option.theme-colors>ul>li.color-blue {
    background: #2D5F8B
}

.theme-panel>.theme-options>.theme-option.theme-colors>ul>li.color-grey {
    background: #697380
}

.theme-panel>.theme-options>.theme-option.theme-colors>ul>li.color-light {
    background: #F9FAFD
}

.theme-panel>.theme-options>.theme-option.theme-colors>ul>li.color-light2 {
    background: #F1F1F1
}

.page-container-bg-solid .theme-panel,.page-content-white .theme-panel {
    position: absolute;
    margin-top: 30px;
    margin-right: 20px;
    right: 0
}

.page-container-bg-solid .theme-panel>.toggler1,.page-content-white .theme-panel>.toggler1 {
    /* background: url(../img/icon-color.png) center no-repeat #BFCAD1 */
}

.page-container-bg-solid.page-sidebar-reversed .theme-panel,.page-content-white.page-sidebar-reversed .theme-panel {
    margin-right: 255px
}

.page-container-bg-solid.page-sidebar-reversed.page-sidebar-closed .theme-panel,.page-content-white.page-sidebar-reversed.page-sidebar-closed .theme-panel {
    margin-right: 65px
}

.page-header .top-menu .dropdown-quick-sidebar-toggler>.dropdown-toggle {
    padding: 17px 10px 9px!important
}

.page-header .top-menu .dropdown-quick-sidebar-toggler>.dropdown-toggle i {
    top: 0
}

.page-header .top-menu .dropdown-quick-sidebar-toggler>.dropdown-toggle i:before {
    content: ""
}

.page-quick-sidebar-open .page-header .top-menu .dropdown-quick-sidebar-toggler>.dropdown-toggle i:before,.page-quick-sidebar-open .page-quick-sidebar-toggler>i:before {
    content: ""
}

.page-portlet-fullscreen .page-quick-sidebar-toggler,.page-portlet-fullscreen .page-quick-sidebar-wrapper {
    z-index: -1
}

.page-quick-sidebar-toggler {
    overflow: hidden;
    z-index: 99999;
    display: none;
    width: 28px;
    height: 27px;
    position: fixed;
    top: 10px;
    right: 15px;
    text-align: center;
    padding-top: 6px
}

.page-quick-sidebar-toggler:hover {
    background: #303a43
}

.page-quick-sidebar-open .page-quick-sidebar-toggler {
    display: inline-block
}

.page-quick-sidebar-open .page-quick-sidebar-toggler:hover {
    background: 0 0
}

.page-quick-sidebar-toggler>i {
    color: #99a8b5;
    font-size: 17px
}

.page-quick-sidebar-toggler>i:hover {
    color: #fff!important
}

.page-quick-sidebar-wrapper {
    transition: right .3s;
    z-index: 10500;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 320px;
    right: -320px;
    overflow: hidden;
    color: #99a8b5;
    background: #21282e
}

.page-quick-sidebar-open .page-quick-sidebar-wrapper {
    transition: right .3s;
    right: 0
}

.page-quick-sidebar-wrapper .page-quick-sidebar {
    background: #21282e
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs {
    margin: 0;
    padding: 0;
    border: 0
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li {
    display: table-cell!important;
    width: 1%!important;
    padding: 0;
    margin: 0;
    float: none
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li>a {
    position: relative;
    display: block;
    text-align: center;
    height: auto;
    font-size: 14px;
    padding: 45px 15px 8px;
    text-transform: uppercase;
    background: 0 0;
    margin-right: 0;
    color: #90a1af;
    border: 0;
    border-bottom: 3px solid rgba(243,86,93,.3);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    outline: 0!important
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li>a>.badge {
    position: absolute;
    top: 45px;
    right: 3px
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li.active>a,.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li:hover>a {
    border: 0;
    border-bottom: 3px solid #f3565d;
    background: 0 0;
    color: #fff;
    text-decoration: none
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li .dropdown-menu {
    border: 0;
    background: #36424c;
    box-shadow: 5px 5px rgba(97,117,135,.1);
    margin-top: 8px;
    margin-right: 20px
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li .dropdown-menu:before {
    position: absolute;
    top: -7px;
    right: 19px;
    display: inline-block!important;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #36424c;
    content: ''
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li .dropdown-menu:after {
    position: absolute;
    top: -6px;
    right: 20px;
    display: inline-block!important;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    border-bottom: 7px solid #36424c;
    content: ''
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li .dropdown-menu>li>a {
    padding: 10px 15px;
    color: #99a8b5
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li .dropdown-menu>li>a>i {
    color: #93a3b1
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li .dropdown-menu>li:hover>a {
    background: #3d4a55;
    color: #99a8b5
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li .dropdown-menu>li:hover>a>i {
    color: #9babb8
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li .dropdown-menu>li.active>a {
    background: #38444f;
    color: #99a8b5
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li .dropdown-menu>li.divider {
    background-color: #3d4a55
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs>li.open>a.dropdown-toggle {
    border-bottom: 3px solid #f3565d;
    background: 0 0;
    text-decoration: none;
    color: #90a1af
}

.page-quick-sidebar-wrapper .page-quick-sidebar .tab-content {
    margin: 0;
    padding: 0
}

.page-quick-sidebar-wrapper .page-quick-sidebar .list-heading {
    font-size: 16px;
    margin: 10px;
    color: #6c8296
}

.page-quick-sidebar-wrapper .page-quick-sidebar .list-items {
    margin: 0;
    padding: 0;
    list-style: none
}

.page-quick-sidebar-wrapper .page-quick-sidebar .list-items>li {
    margin: 0;
    padding: 15px;
    background: 0 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #273037
}

.page-quick-sidebar-wrapper .page-quick-sidebar .list-items>li:hover {
    background: #273037
}

.page-quick-sidebar-wrapper .page-quick-sidebar .list-items>li:last-child {
    border-bottom: 0
}

.page-quick-sidebar-wrapper .page-quick-sidebar .list-items.borderless li {
    border: 0
}

.page-quick-sidebar-wrapper .page-quick-sidebar .inner-content {
    margin: 10px
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-list {
    position: absolute!important;
    width: 320px!important;
    transition: margin .3s
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-item {
    position: absolute!important;
    width: 320px!important;
    transition: margin .3s;
    margin-left: 320px
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-item .page-quick-sidebar-nav {
    padding: 15px 10px 0
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-item .page-quick-sidebar-nav .page-quick-sidebar-back-to-list {
    vertical-align: middle;
    display: inline-block;
    font-size: 14px;
    color: #90a1af
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-item .page-quick-sidebar-nav .page-quick-sidebar-back-to-list:hover {
    text-decoration: none
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-item .page-quick-sidebar-nav .page-quick-sidebar-back-to-list>i {
    font-size: 17px;
    line-height: 17px;
    vertical-align: top;
    margin-right: 3px
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-content-item-shown .page-quick-sidebar-list {
    transition: margin .3s;
    margin-left: -320px
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-content-item-shown .page-quick-sidebar-list .slimScrollBar,.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-content-item-shown .page-quick-sidebar-list .slimScrollRail {
    display: none!important
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-content-item-shown .page-quick-sidebar-item {
    transition: margin .3s;
    margin-left: 0
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users {
    padding: 10px 0;
    position: relative
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media {
    padding: 15px
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-object {
    border-radius: 50%!important;
    width: 45.71px;
    opacity: .8;
    filter: alpha(opacity=80);
    float: left;
    margin-right: 10px
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-object:after,.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-object:before {
    content: " ";
    display: table
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-object:after {
    clear: both
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media:hover {
    cursor: pointer
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media:hover .media-object {
    opacity: 1;
    filter: alpha(opacity=100)
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-body .media-heading {
    margin: 5px 0 0;
    font-size: 14px
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-body .media-heading-sub {
    font-size: 11px;
    text-transform: uppercase;
    color: #657b8d
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-body .media-heading-small {
    font-size: 10px;
    color: #5d7081
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-status {
    margin-top: 10px;
    right: 10px;
    position: absolute;
    display: inline-block
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages {
    padding: 0 10px;
    position: relative
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post {
    transition: display .3s;
    padding: 5px 0;
    margin: 10px auto;
    font-size: 13px
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post .body {
    color: #c3c3c3;
    display: block
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post .avatar {
    width: 45.71px;
    border-radius: 50%!important
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.in .avatar {
    float: left;
    margin-right: 10px
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.out .avatar {
    float: right;
    margin-left: 10px
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post .datetime,.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post .name {
    font-size: 12px;
    font-weight: 300;
    color: #8496a7
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post .message {
    display: block;
    padding: 5px;
    position: relative;
    color: #90a1af;
    background: #36424c
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.in .message {
    text-align: left;
    margin-left: 55px
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.in .message .arrow {
    display: block;
    position: absolute;
    top: 9px;
    left: -6px;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right-width: 6px;
    border-right-style: solid;
    border-right-color: #36424c
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.out .message {
    margin-right: 55px;
    text-align: right
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.out .message .arrow {
    display: block;
    position: absolute;
    top: 9px;
    right: -6px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left-width: 6px;
    border-left-style: solid;
    border-left-color: #36424c
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.out .datetime,.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.out .name {
    text-align: right
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-form {
    padding: 20px 10px 15px
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-alerts .page-quick-sidebar-alerts-list {
    padding: 10px 0;
    position: relative
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-alerts .page-quick-sidebar-alerts-list .feeds li a {
    color: #7e91a2
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-alerts .page-quick-sidebar-alerts-list .feeds li a .label {
    margin-top: 5px
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-alerts .page-quick-sidebar-alerts-list .feeds li a .desc {
    text-decoration: underline;
    padding: 0;
    color: #788c9e
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-alerts .page-quick-sidebar-alerts-list .feeds li a .date {
    color: #5d7081
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-settings .page-quick-sidebar-settings-list {
    padding: 10px 0;
    position: relative
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-settings .page-quick-sidebar-settings-list .list-items li .bootstrap-switch {
    margin-top: -3px;
    float: right;
    border: 0;
    min-width: 59px
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-settings .page-quick-sidebar-settings-list .list-items li .form-control {
    width: 75px!important;
    padding: 4px!important;
    float: right;
    border: 0;
    margin-top: -4px
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-settings .page-quick-sidebar-settings-list .list-items li select.form-control {
    padding: 4px 0!important
}

.page-on-load {
    background: #fefefe
}

.page-on-load .page-container,.page-on-load .page-footer,.page-on-load .page-header,.page-on-load>.clearfix {
    display: none;
    transition: all 2s
}
